import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "pt-4 pb-3 px-4 flex-grow" }
const _hoisted_4 = { href: "#" }
const _hoisted_5 = { class: "flex items-baseline mb-1 space-x-2 flex-wrap" }
const _hoisted_6 = { class: "text-sm text-gray-400 line-through" }

import { useEcommerce } from '@/composables/useEcommerce';
import { Product } from '@/interfaces/products/Product';
import { generateProductSlug } from '@/interfaces/products/Product';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductSection',
  props: {
    products: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "78816dd3": ((_unref(ecommerce).visual?.productsPerRow ?? 3) - 1),
  "78816dd4": ((_unref(ecommerce).visual?.productsPerRow ?? 3) - 2),
  "38e0a698": (_unref(ecommerce).visual?.mobileProductsPerRow === 2 ? '1fr 1fr' : '1fr')
}))

const { ecommerce } = useEcommerce()


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid grid-cols-1 gap-6 product-section", [`grid-cols-${_unref(ecommerce).visual?.productsPerRow ?? 3}`]])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
      return (_openBlock(), _createBlock(_component_RouterLink, {
        style: _normalizeStyle({ borderRadius: _unref(ecommerce).visual?.productThumbnailBorderRadius, maxHeight: _unref(ecommerce).visual?.productThumbnailHeight }),
        to: { name: 'product', params: { slug: _unref(generateProductSlug)(product.title) } },
        class: _normalizeClass([[_unref(ecommerce).visual?.displayProductBorder ? 'shadow' : ''], "bg-white rounded overflow-hidden group h-full flex flex-col"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: product.images?.length ? product.images[0].source : '/assets/images/no-image.png',
              alt: "product 1",
              class: "w-full object-contain",
              onError: _cache[0] || (_cache[0] = ($event: any) => (($event.target as HTMLImageElement).src = '/assets/images/no-image.png'))
            }, null, 40, _hoisted_2),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center gap-2 opacity-0 group-hover:opacity-100 transition" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("a", _hoisted_4, [
              _createElementVNode("h4", {
                class: "font-medium text-xl mb-2 text-gray-800 hover:text-primary transition",
                style: _normalizeStyle({ fontFamily: _unref(ecommerce).visual?.productThumbnailTitleFontFamily, fontSize: _unref(ecommerce).visual?.productThumbnailTitleFontSize, fontWeight: _unref(ecommerce).visual?.productThumbnailTitleFontWeight, color: _unref(ecommerce).visual?.colorProductThumbnailTitle })
              }, _toDisplayString(product.title), 5)
            ]),
            _createElementVNode("div", _hoisted_5, [
              (product.variants[0].promotionalPrice)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("p", {
                      class: "text-xl text-primary font-semibold",
                      style: _normalizeStyle({ color: _unref(ecommerce).visual?.colorProductThumbnailPrice, fontSize: _unref(ecommerce).visual?.productThumbnailPriceFontSize })
                    }, _toDisplayString(product.variants[0].promotionalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 5),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    class: "text-xl text-primary font-semibold",
                    style: _normalizeStyle({ color: _unref(ecommerce).visual?.colorProductThumbnailPrice, fontSize: _unref(ecommerce).visual?.productThumbnailPriceFontSize })
                  }, _toDisplayString(product.variants[0].price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })), 5))
            ])
          ]),
          (_unref(ecommerce).visual?.displayShowProductButton)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: "#",
                class: "block w-full py-1 text-center text-white bg-primary border border-primary rounded-b hover:bg-transparent hover:text-primary transition flex items-center justify-center",
                style: _normalizeStyle({ height: _unref(ecommerce).visual?.showProductButtonHeight, background: _unref(ecommerce).visual?.colorShowProductButtonBackground, color: _unref(ecommerce).visual?.colorShowProductButtonText })
              }, " Ver mais ", 4))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["style", "to", "class"]))
    }), 256))
  ], 2))
}
}

})
import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

import PinkThemeHeader from './components/PinkThemeHeader.vue';
import PinkThemeNav from './components/PinkThemeNav.vue';
import PinkThemeFooter from './components/PinkThemeFooter.vue';
import FloatingButton from './components/FloatingButton.vue';
import Loader from '@/components/loaders/Loader.vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useProduct } from '@/composables/useProduct';
import { useEcommerce } from '@/composables/useEcommerce';


export default /*@__PURE__*/_defineComponent({
  __name: 'PinkThemeLayout',
  setup(__props) {

_useCssVars(_ctx => ({
  "3b840f4c": (_unref(ecommerce)?.visual?.colorPrimary ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'),
  "7c004455": (_unref(ecommerce).visual?.colorMenuText),
  "0363af14": (_unref(ecommerce).visual?.colorMenuHover)
}))

const { isEcommerceLoading, ecommerce } = useEcommerce()
const { isCategoriesLoading } = useCategory()
const { isCollectionsLoading } = useCollection()
const { isProductsLoading } = useProduct()

return (_ctx: any,_cache: any) => {
  return (!_unref(isEcommerceLoading) && !_unref(isCategoriesLoading) && !_unref(isCollectionsLoading) && !_unref(isProductsLoading))
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createVNode(PinkThemeHeader),
        _createVNode(PinkThemeNav),
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(FloatingButton),
        _createVNode(PinkThemeFooter)
      ]))
    : (_openBlock(), _createBlock(Loader, { key: 1 }))
}
}

})
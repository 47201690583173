<template>
  <main v-if="!isEcommerceLoading && !isCategoriesLoading && !isCollectionsLoading && !isProductsLoading">
    <PinkThemeHeader />
    <PinkThemeNav />
    <slot />
    <FloatingButton />
    <PinkThemeFooter />
  </main>
  <Loader v-else />
</template>

<script setup lang="ts">
import PinkThemeHeader from './components/PinkThemeHeader.vue';
import PinkThemeNav from './components/PinkThemeNav.vue';
import PinkThemeFooter from './components/PinkThemeFooter.vue';
import FloatingButton from './components/FloatingButton.vue';
import Loader from '@/components/loaders/Loader.vue';
import { useCategory } from '@/composables/useCategory';
import { useCollection } from '@/composables/useCollection';
import { useProduct } from '@/composables/useProduct';
import { useEcommerce } from '@/composables/useEcommerce';

const { isEcommerceLoading, ecommerce } = useEcommerce()
const { isCategoriesLoading } = useCategory()
const { isCollectionsLoading } = useCollection()
const { isProductsLoading } = useProduct()
</script>

<style lang="scss">
@import './../../../../assets/css/main.css';

$primary-color: v-bind("ecommerce?.visual?.colorPrimary ?? 'rgb(253 61 87 / var(--tw-bg-opacity))'");
$menu-color: v-bind("ecommerce.visual?.colorMenuText");
$menu-color-hover: v-bind("ecommerce.visual?.colorMenuHover");

.text-menu {
  color: v-bind("ecommerce.visual?.colorMenuText")
}
.text-menu-hover, .hover\:text-menu-hover:hover {
  color: $menu-color-hover !important
}

.bg-primary { 
  background: $primary-color
}
.border-primary {
  border-color: $primary-color
}
.text-primary {
  color: $primary-color
}
.fill-primary {
  fill: $primary-color
}
.hover\:text-primary:hover {
  color: $primary-color
}
.simple-spinner .loader {
  border: 5px solid $primary-color
}
</style>
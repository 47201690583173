import { useCache } from '@/composables/useCache'
import { useCategory } from '@/composables/useCategory'
import { useCollection } from '@/composables/useCollection'
import { useEcommerce } from '@/composables/useEcommerce'
import { useProduct } from '@/composables/useProduct'
import CartView from '@/views/cart/CartView.vue'
import HomeView from '@/views/HomeView.vue'
import EcommerceNotFoundView from '@/views/notFound/EcommerceNotFoundView.vue'
import NotFoundView from '@/views/notFound/NotFoundView.vue'
import ShowProductView from '@/views/products/ShowProductView.vue'
import ShopView from '@/views/shop/ShopView.vue'
import PinkThemeDynamicView from '@/views/themes/pink/dynamicView/PinkThemeDynamicView.vue'
import PasswordView from '@/views/themes/pink/password/PasswordView.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product/:slug',
    name: 'product',
    props: true,
    component: ShowProductView
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopView
  },
  {
    path: '/categories/:slug',
    name: 'categories',
    props: true,
    component: ShopView
  },
  {
    path: '/collections/:slug',
    name: 'collections',
    props: true,
    component: ShopView
  },
  {
    path: '/ecommerce-not-found',
    name: 'ecommerce.not-found',
    component: EcommerceNotFoundView
  },
  {
    path: '/:slug',
    name: 'dynamic-page',
    component: PinkThemeDynamicView,
    props: true
  },
  {
    path: '/password',
    name: 'password',
    component: PasswordView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

const { ecommerce, loadEcommerce } = useEcommerce()
const { needUpdate } = useCache()
const { loadCategories } = useCategory()
const { loadCollections } = useCollection()
const { loadProducts } = useProduct()

router.beforeEach(async (to, from, next) => {
  const lastUpdate = await needUpdate();
  const localLastUpdate = localStorage.getItem('lastUpdate');

  const isUpdated = localLastUpdate !== String(lastUpdate);

  if (isUpdated) {
    localStorage.setItem('lastUpdate', String(lastUpdate));
  }

  const shouldReload = isUpdated; // `true` para atualizar, `false` se já estiver sincronizado
  loadEcommerce(shouldReload);
  loadCategories(shouldReload);
  loadCollections(shouldReload);
  loadProducts(shouldReload);

  if (window.location.href.includes('https://hooshop.com.br')) {
    window.location.href = 'https://hoopay.com.br'
  }

  if (window.location.href.includes('hooshop')) {
    const storedPassword = localStorage.getItem('password');
    const ecommercePassword = ecommerce?.value?.password;

    if (!storedPassword || (storedPassword !== ecommercePassword && ecommerce.value)) {
      if (to.name !== 'password') {
        return next({ name: 'password' });
      }
    }
  }
  next();
});

export default router
